export const splideObj = {};
export default function multiSlidersInit() {
    const $multiSliders = document.querySelectorAll('.multi-slider');
    const activeClass = 'js-active';
    let button;
    let target;
    let currentMultiSlider;

    const toggleActiveSlider = (targetButton, targetSlider, multiSlider) => {
        const buttons = multiSlider.getElementsByClassName('button-item');
        const sliders = multiSlider.getElementsByClassName('bloc-products');
        const splideElement = targetSlider.getElementsByClassName('splide')[0];
        const splideId = splideElement.getAttribute('data-item-id');
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove(activeClass);
        }

        targetButton.classList.add(activeClass);
        for (let i = 0; i < sliders.length; i++) {
            sliders[i].classList.remove(activeClass);
        }

        targetSlider.classList.add(activeClass);
        splideObj[splideId].refresh();
    };

    const checkMultiSlider = (multiSlider) => {
        const $multiSlider = multiSlider;
        const $buttonsList = $multiSlider.querySelector('.buttons-list');
        const $multiSlidersButtons = $buttonsList.querySelectorAll('.button-item');
        if ($multiSlidersButtons.length > 1) {
            $buttonsList.classList.add(activeClass);
        } else {
            $buttonsList.remove();
        }

        $multiSlidersButtons.forEach((el, index) => {
            $multiSlidersButtons[index].addEventListener('click', (e) => {
                button = e.target;
                currentMultiSlider = button.closest('.multi-slider');
                [target] = currentMultiSlider.getElementsByClassName(button.getAttribute('data-slider-target'));
                toggleActiveSlider(button, target, currentMultiSlider);
            });
        });
    };

    if ($multiSliders) {
        $multiSliders.forEach((el, index) => {
            checkMultiSlider($multiSliders[index]);
        });
    }
}
