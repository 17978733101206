import Splide from '@splidejs/splide';

export default function thumbnailsSliderInit() {
    const allThumbnailsSlider = document.querySelectorAll('.thumbnails-slider');

    allThumbnailsSlider.forEach((slider) => {
        const initializedSlider = new Splide(slider, { type: 'loop' });
        const thumbnails = slider.querySelectorAll('.thumbnail-picture');

        initializedSlider.on('pagination:mounted', (data) => {
            data.list.classList.add('punchlines-list-buttons');

            data.items.forEach((item, index) => {
                const paginationItem = item;
                const labelButton = thumbnails[index].getAttribute('data-thumbnail-text');

                paginationItem.button.textContent = String(labelButton);
                paginationItem.button.classList.add('punchline-button');
            });
        });

        initializedSlider.mount();
    });
}
