import { dynamicallyLoadScript } from '../utils.js';

const datalayer = {};

export function updateDatalayer(...args) {
    if (args.length === 1 && typeof args[0] === 'object') {
        const dataObject = args[0];
        Object.assign(datalayer, dataObject);
    } else if (args.length === 2 && typeof args[0] === 'string') {
        const [key, value] = args;
        datalayer[key] = value;
        window.tc_vars[key] = value;
    }
    document.dispatchEvent(new CustomEvent('datalayer_updated.tagcommander', { detail: datalayer }));
}

export function triggerTracking(pEventValue, pTrackName, pEventName) {
    let eventName = 'event_id';
    if (pEventName) {
        eventName = pEventName;
    }
    try {
        updateDatalayer(eventName, pEventValue);
        // eslint-disable-next-line no-underscore-dangle
        window._satellite.track(pTrackName);
    } catch (e) {
        console.warn("_satellite n'est pas défini");
    }
}

export function updateEventID(element) {
    const eventName = element.getAttribute('data-tracking-event');
    const trackName = element.getAttribute('data-tracking-name');

    triggerTracking(eventName, trackName);
}

function listenOnloadTracking() {
    document.addEventListener('click', (event) => {
        if (event.target.matches('DOMContentLoaded')) {
            const onloadElement = document.querySelector('[data-tracking-onload]');
            if (onloadElement.length) {
                updateEventID(onloadElement);
            }
        }
    });
}

export function loadOmniture() {
    const url = document.querySelector('body').getAttribute('data-omniture-url');
    dynamicallyLoadScript(url);
    listenOnloadTracking();
}
