import sendBeaconTracking from './advertisement_tracking_init.js';
import initializeDynamicComponents from './advertisement_components_init.js';

// Construction de l'url pour le fetch suivant les pages
const getUrlParamsToFetch = () => {
    const isHomePage = document.getElementById('page-home') !== null;
    const isUniversePage = document.getElementById('page-universe') !== null;
    let params = new URLSearchParams(window.location.search);
    let paramPageUrl = '';

    if (isHomePage) {
        paramPageUrl = 'home_page';
    } else if (isUniversePage) {
        paramPageUrl = 'universe_page';
        params = `${params}&universeId=${document.getElementsByClassName('advertisement-container')[0].dataset.universeId}`;
    }

    const url = `/nav/extra/ajax/advertisement/${paramPageUrl}?${params.toString()}`;
    return url;
};

const fillTop = (container, responseContent) => {
    let format = '';
    let advertisementContent = '';
    advertisementContent = responseContent.querySelectorAll(
        '[data-format="billboard"], [data-format="billboard_display"], [data-format="sword"], [data-format="sword_native"], [data-format="sword_display"]'
    );

    if (advertisementContent[0]?.className.includes('billboard')) {
        format = 'billboard';
    } else if (advertisementContent[0]?.className.includes('sword')) {
        format = 'sword';
    }

    if (advertisementContent.length === 0) {
        document.querySelector('[data-position="advertisement-top"]').remove();
    } else {
        document.querySelector('[data-position="advertisement-top"]').classList.add(format);
        document.querySelector('[data-position="advertisement-top"]').innerHTML = Array.from(advertisementContent)
            .map((node) => node.outerHTML)
            .join('');

        if (container.querySelector('.advertisement-content') !== null) {
            initializeDynamicComponents(container);
        }
    }
};

const fillBottom = (container, responseContent) => {
    let advertisementContent = '';
    advertisementContent = responseContent.querySelectorAll(
        '[data-format="impulse"], [data-format="impulse_display"], [data-format="impulse_product"]'
    );
    if (advertisementContent.length === 0) {
        document.querySelector('[data-position="advertisement-bottom"]').remove();
    } else {
        document.querySelector('[data-position="advertisement-bottom"]').innerHTML = Array.from(advertisementContent)
            .map((node) => node.outerHTML)
            .join('');

        if (container.querySelector('.advertisement-content') !== null) {
            initializeDynamicComponents(container);
        }
    }
};

// Récupère le contenu publicitaire
const fetchAdvertisementContent = async () => {
    const response = await fetch(getUrlParamsToFetch());
    const contentType = response.headers.get('content-type');

    if (!contentType?.includes('application/json')) {
        const text = await response.text();
        const advertisementContainer = document.querySelectorAll('.advertisement-container');
        if (advertisementContainer.length > 0) {
            advertisementContainer.forEach(async (container) => {
                const { position } = container.dataset;
                const parser = new DOMParser();
                const htmlDocument = parser.parseFromString(text, 'text/html');
                const responseContent = htmlDocument.body;
                switch (position) {
                    case 'advertisement-top': {
                        fillTop(container, responseContent);
                        break;
                    }
                    case 'advertisement-bottom': {
                        fillBottom(container, responseContent);
                        break;
                    }
                    default: {
                        break;
                    }
                }

                container.classList.remove('js-loading');
            });
        }
    }
    return null;
};

export default function advertisementInit() {
    (async () => {
        const container = document.querySelector('.advertisement-container');
        if (container) {
            const text = await fetchAdvertisementContent();
            container.classList.remove('js-loading');
            if (text === '') {
                container.remove();
            } else {
                if (container.querySelector('.advertisement-content') !== null) {
                    const impBeacon = container.querySelector('.advertisement-content').getAttribute('data-imp-beacon');
                    sendBeaconTracking(impBeacon);
                }

                const event = new CustomEvent('advertissementLoaded', {
                    detail: {
                        content: text
                    }
                });
                document.dispatchEvent(event);
            }
        }
    })();
}
