export default function getAvailabilityMessage(msg, city, url, family) {
    if (msg === 'no_available_error') {
        return `Ce produit n'est plus disponible à la livraison à ${city}.<br><a href="${
            url
        }" class="availability-link">Voir tous les ${family} livrés dans votre ville.</a>`;
    }

    if (msg === 'zipcode_error') {
        return "Le code postal que vous avez saisi n'est pas reconnu";
    }

    if (msg === 'zipcode_hzc_error') {
        return 'La livraison avec installation Darty n\'est pas disponible car votre ville n\'est pas située dans une zone de confiance Darty (<a href="https://www.darty.com/res3/services/contrat-de-confiance/pdf/cdc_zone_de_confiance_darty.pdf" class="availability-link" target="_blank">voir les zones de confiance</a>).';
    }

    if (msg === 'zipcode_does_not_return_zone') {
        return "La livraison avec installation darty n'est pas disponible car votre ville n'est pas située dans une zone de livraison Darty";
    }

    if (msg === 'no_product_available') {
        return `Aucun produit n'est disponible à ${city}`;
    }
    return msg;
}
