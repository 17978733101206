import advertisementTrackingVideoInit from './advertisement_tracking_video.js';
import sendProductTracking from './advertisement_product_tracking.js';

// Constantes pour les marges de temps
const MAX_TIME_DIFF = 0.5;

// Initialise la vidéo de fond
const initVideoBackground = (videoSrc, id) => {
    const video = document.createElement('video');
    video.setAttribute('id', `background-${id}`);
    video.src = videoSrc;
    video.autoplay = true;
    video.muted = true;
    video.loop = false;
    video.play();
    const canvasId = `canvas-${id}`.replace('_html5_api', '');
    const canvas = document.querySelector(`[id^=${canvasId}]`);
    if (!canvas) {
        console.error(`Canvas element with ID ${canvasId} not found while initializing video background.`);
        return null;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
        console.error('Cannot get canvas context while initializing video background.');
        return null;
    }

    const draw = () => {
        ctx.filter = 'blur(10px)';
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        requestAnimationFrame(draw);
    };

    draw();

    return video;
};

// Synchronise les contrôles vidéo
const syncVideoControls = (mainVideo, originalBackgroundVideo) => {
    const backgroundVideo = videojs(originalBackgroundVideo);

    if (backgroundVideo) {
        ['play', 'pause', 'ended'].forEach((event) => {
            mainVideo.on(event, () => {
                if (event === 'ended') {
                    backgroundVideo.pause();
                } else {
                    backgroundVideo[event]();
                }
            });
        });

        mainVideo.on('timeupdate', () => {
            const timeDiff = Math.abs(mainVideo.currentTime() - backgroundVideo.currentTime);
            if (timeDiff > MAX_TIME_DIFF) {
                backgroundVideo.currentTime = mainVideo.currentTime();
            }
        });
    }
};

// Initialise splides
const initSplide = (container) => {
    const splideElements = container.querySelectorAll('.advertisement-container .splide');
    splideElements.forEach((splideElement) => {
        if (splideElement) {
            const format = splideElement.closest('[data-format]').getAttribute('data-format');
            let splideOptions = {
                drag: true,
                pagination: true,
                arrows: true,
                snap: true,
                gap: 10,
                type: 'slide',
                perPage: splideElement?.dataset.itemsPerPage ? splideElement.dataset.itemsPerPage : 1,
                perMove: 1,
                lazyLoad: true,
                breakpoints: {
                    768: {
                        arrows: false,
                        perPage: 1
                    },
                    1024: {
                        perPage: splideElement?.dataset.itemsPerPageLaptop
                            ? splideElement.dataset.itemsPerPageLaptop
                            : 1
                    },
                    1280: {
                        perPage: splideElement?.dataset.itemsPerPageDesktop
                            ? splideElement.dataset.itemsPerPageDesktop
                            : 1
                    },
                    1600: {
                        perPage: splideElement?.dataset.itemsPerPageDesktopXl
                            ? splideElement.dataset.itemsPerPageDesktopXl
                            : 1
                    }
                },
                i18n: {
                    prev: 'Slide précédente',
                    next: 'Slide suivante',
                    carousel: 'Slider',
                    slideLabel: 'Slide %s'
                }
            };
            if (format === 'impulse' || format === 'impulse_product') {
                splideOptions = {
                    ...splideOptions,
                    pagination: true,
                    fixedHeight: 120
                };
            }
            const splide = new Splide(splideElement, splideOptions);
            splide.mount();
            sendProductTracking(container, splide);
        }
    });
};

// Initialise video
const initVideos = (container) => {
    const videoContainer = container.querySelector('.clickable-area');
    if (!videoContainer) {
        console.warn('Video container not found during dynamic components initialization.');
        return;
    }

    const videoUrl = videoContainer.getAttribute('data-video-url');
    if (!videoUrl) {
        console.warn('No video URL found during dynamic components initialization.');
        return;
    }

    const mainVideoElement = videoContainer.querySelector('video');
    if (!mainVideoElement) {
        console.warn('Main video element not found during dynamic components initialization.');
        return;
    }

    const mainVideo = videojs(mainVideoElement.id, null, function initMainVideo() {
        advertisementTrackingVideoInit();
    });

    const isPortraitFormat = videoContainer.classList.contains('with-background-format');

    if (isPortraitFormat || container.dataset.format === 'impulse') {
        const backgroundVideo = initVideoBackground(videoUrl, mainVideoElement.id);
        if (!backgroundVideo) {
            console.warn('Background video initialization failed during dynamic components initialization.');
            return;
        }
        syncVideoControls(mainVideo, backgroundVideo);
    }
};

const initMicromodal = () => {
    MicroModal.init({
        onShow: () => {
            initVideos(document.querySelector('[data-js="popin_video"]'));
        }
    });
};

// Initialise les composants dynamiques
export default function initializeDynamicComponents(container) {
    initSplide(container);
    initVideos(container);
    initMicromodal();
    container.classList.remove('js-loading');
}
