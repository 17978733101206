import sendBeaconTracking, { clickTrackingData } from './advertisement_tracking_init.js';

const addToCartClick = function (container, beaconData) {
    const addToCartBtns = container.querySelectorAll('[data-add-to-basket], [data-click-and-collect]');
    for (const addToCartBtn of addToCartBtns) {
        addToCartBtn.addEventListener('click', function onClick() {
            const productId = this.closest('.product_pub-card').getAttribute('data-codic');
            sendBeaconTracking(
                clickTrackingData(beaconData, `click_zone=product&act=add-to-cart&product_id=${productId}`)
            );
        });
    }
};

const productClick = function (container, beaconData) {
    const productLinks = container.querySelectorAll('.product_pub-link');

    for (const productLink of productLinks) {
        productLink.addEventListener('click', function onClick() {
            const productId = this.closest('.product_pub-card').getAttribute('data-codic');
            sendBeaconTracking(
                clickTrackingData(beaconData, `click_zone=product&act=product-page&product_id=${productId}`)
            );
        });
    }
};

const slideNavigationTracking = function (container, beaconData, splide) {
    const nextBtn = container.querySelector('.splide__arrow--next');
    const prevBtn = container.querySelector('.splide__arrow--prev');

    if (nextBtn) {
        nextBtn.addEventListener('click', () => {
            sendBeaconTracking(clickTrackingData(beaconData, 'click_zone=format&act=nav-next'));
        });
    }

    if (prevBtn) {
        prevBtn.addEventListener('click', () => {
            sendBeaconTracking(clickTrackingData(beaconData, 'click_zone=format&act=nav-prev'));
        });
    }

    splide.on('move', function handleMove(newIndex, oldIndex) {
        if (newIndex > oldIndex) {
            sendBeaconTracking(clickTrackingData(beaconData, 'click_zone=format&act=nav-next'));
        } else {
            sendBeaconTracking(clickTrackingData(beaconData, 'click_zone=format&act=nav-prev'));
        }
    });
};

export default function sendProductTracking(container, splide) {
    const clickBeaconData = container.querySelector('.advertisement-content').getAttribute('data-click-beacon');
    addToCartClick(container, clickBeaconData);
    productClick(container, clickBeaconData);
    slideNavigationTracking(container, clickBeaconData, splide);
}
