import adsNexusInit from '../ads_nexus.js';
import multiSlidersInit from '../multi_sliders.js';
import micromodalInit from '../micromodal_init.js';
import { splideInit } from '../splide.js';
import advertisementInit from '../../../version_common/scripts/advertisement/advertisement.js';
import addDeliverableInit from '../add_deliverable.js';
import thumbnailsSliderInit from '../block_thumbnails_slider.js';

(() => {
    adsNexusInit();
    splideInit();
    advertisementInit();
    window.addEventListener('load', () => {
        multiSlidersInit();
    });
    addDeliverableInit();
    thumbnailsSliderInit();
    document.addEventListener('advertissementLoaded', () => {
        addDeliverableInit();
        micromodalInit();
    });
})();
